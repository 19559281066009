.right {
  text-align: right;
}

.center {
  text-align: center;
}

.text-small {
  font-size: 12px;
}

.bold {
  font-weight: 600;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fs-45 {
  font-size: 45px;
}

.upper {
  text-transform: uppercase;
}
